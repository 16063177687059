import { useState, useEffect, useCallback } from "react";
import env from "react-dotenv";
import Select from 'react-select';
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import { useSearchParams, useLocation, Outlet } from "react-router-dom";

function Layout() {

  const [stores, setStores] = useState([]);
  const [store, setStore] = useState({});
  const [membership, setMembership] = useState({});
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();

  const { pathname } = useLocation();

  const [searchParams] = useSearchParams();

  const auth = useAuth0();

  const langs = [
    {value: 'it', label: 'it'},
    {value: 'en', label: 'gb'},
  ];

  const loadUser = useCallback(async () => {
    setLoading(true);
    const accessToken = await auth.getAccessTokenSilently({
      authorizationParams: {
        audience: env.AUTH0_AUDIENCE,
        scope: "read:current_user",
      },
    });
    const userData = await fetch(env.AUTH0_API_SERVER+"user", {
      headers: {
        "Content-Type":"application/json",
        "Authorization": "Bearer "+accessToken,
      },
    }).then((res) => res.json());
    window.sessionStorage.setItem('userdata', JSON.stringify(userData));
    if (userData.stores.length > 0 && userData.stores.findIndex((v) => v.code === window.sessionStorage.getItem('store')) < 0) {
      window.sessionStorage.setItem('store', userData.stores[0].code);
    }
    setStores(userData.stores);
    setStore(userData.stores?.find((v) => v.code === window.sessionStorage.getItem('store')));
    setMembership(userData.memberships?.find((v) => v.store === window.sessionStorage.getItem('store')));
    setLoading(false);
  }, [auth]);

  const login = async () => {
    await auth.loginWithPopup({authorizationParams: {connection: "Username-Password-Authentication"}});
    await loadUser();
  };

  const loginPasswordless = async () => {
    await auth.loginWithPopup({authorizationParams: {connection: "email"}});
    await loadUser();
  };

  const logout = async () => {
    await auth.logout({logoutParams: {returnTo: window.location.origin}})
    window.sessionStorage.removeItem('store');
    window.sessionStorage.removeItem('userdata');
    setStores([]);
    setStore({});
    setMembership({});
  };

  useEffect(() => {
    const initData = async () => {
      if (window.sessionStorage.getItem('userdata')) {
        const userData = JSON.parse(window.sessionStorage.getItem('userdata'));
        if (window.sessionStorage.getItem('store') && userData.stores.findIndex((x) => x.code === window.sessionStorage.getItem('store')) < 0) {
          window.sessionStorage.removeItem('store', userData.stores[0].code);
        }
        if (!window.sessionStorage.getItem('store') && userData.stores.length > 0) {
          window.sessionStorage.setItem('store', userData.stores[0].code);
        }
        setStores(userData.stores);
        setStore(userData.stores?.find((v) => v.code === window.sessionStorage.getItem('store')));
        setMembership(userData.memberships?.find((v) => v.store === window.sessionStorage.getItem('store')));
      } else if (auth.isAuthenticated) {
        loadUser();
      }
    };
    if (searchParams.get('redirect')) {
      window.location.href = searchParams.get('redirect');
    } else {
      initData();
      if (window.localStorage.getItem('lang')) {
        i18n.changeLanguage(window.localStorage.getItem('lang'));
      } else {
        window.localStorage.setItem('lang', i18n.language)
      }
    }
  }, [auth, loadUser, searchParams, i18n])

  return (
    <section id="layout">
      <header className="header">
        <nav className="navbar navbar-expand">
          <div className="container-fluid">
            <div className="navbar-brand">
              {auth.isAuthenticated && <button className="btn auth-button" onClick={() => logout()}>{t('logout')}</button>}
            </div>
            <div className="justify-content-end" id="navbarContent">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Select className="nav-link" defaultValue={langs.find((x) => x.value === i18n.language)} options={langs} formatOptionLabel={(x) => {return (<span className={'fi fi-'+x.label}></span>)}} onChange={(x) => {window.localStorage.setItem('lang', x.value); i18n.changeLanguage(x.value);}} />
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/" data-bs-toggle="tooltip" data-bs-placement="left" title="Home Page">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-house-fill" viewBox="0 0 16 16">
                      <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z"/>
                      <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z"/>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <main className="main container">
        {!loading && <div className="text-center">
          <div className="affiliation-title-outer">
            <h1 className="affiliation-title">Moonycard</h1>
          </div>
          {auth.isAuthenticated && store && <h3 className="affiliation-subtitle" >{store.name}</h3>}
          {auth.isAuthenticated && stores && stores.length > 1 && <Select className="affiliation-select" options={stores} getOptionLabel={(x) => x.name} getOptionValue={(x) => x.code} onChange={(x) => {window.sessionStorage.setItem('store', x.code); window.location.replace(window.location.href);}} />}
          {auth.isAuthenticated && membership?.role === 'admin' && <div>
            <p>{t('available_token_balance')}: {store.tokens}</p>
            <a className="btn item-button d-none" href="/tokens" data-bs-toggle="tooltip" data-bs-placement="right" title={t('buy')}>{t('buy')}</a>
          </div>}
          {(!auth.isAuthenticated || window.sessionStorage.getItem('store')) && <Outlet context={[store, membership, loadUser]} />}
          {!auth.isAuthenticated && pathname === '/' && <div className="affiliation-section">
            <div className="mb-5">
              <button className="btn auth-button" onClick={() => login()}>{t('login_password')}</button>
            </div>
            <div className="mb-5">
              <button className="btn auth-button" onClick={() => loginPasswordless()}>{t('login_otp')}</button>
            </div>
          </div>}
        </div>}
        {loading && <div className="mt-5 d-flex align-items-center justify-content-center">
          <div className="spinner-border" style={{width: '10rem', height: '10rem',}}></div>
        </div>}
      </main>
      <footer className="footer">
        <div className="text-center mt-5">
          <div className=" mb-5">
            <span>&#169; Moonycard</span>
          </div>
        </div>
      </footer>

    </section>
  );
}

export default Layout;
